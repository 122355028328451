<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="150px" :model="formLabelAlign">
        <el-form-item
          label="标题："
          prop="name"
          :rules="[{ required: true, message: '请输入标题' }]"
        >
          <el-input
            placeholder="请输入标题"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.name"
          ></el-input>
        </el-form-item>

        <el-form-item label="部门：" prop="department_id">
          <BepartmentMulti ref="BepartmentMulti" />
        </el-form-item>

        <el-form-item
          label="分值："
          prop="score"
          :rules="[{ required: true, message: '请选择输入分值' }]"
        >
          <el-input
            type="number"
            placeholder="请输入分值"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.score"
          ></el-input>
        </el-form-item>
        <div style="display: flex">
          <el-form-item label="巡检周期：">
            <el-select
              v-model="formLabelAlign.check_times"
              placeholder="请选择巡检周期"
            >
              <el-option
                v-for="item in check_times"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label-width="0px"
            v-if="formLabelAlign.check_times == 5"
            prop="times_days"
          >
            <el-input
              style="width: 200px;height:100%"
              placeholder="请输入天数"
              size="mini"
              class="form-inp"
              v-model.number="formLabelAlign.times_days"
            >
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
        </div>
        <el-form-item
          label="简述："
          prop="desc"
          :rules="[{ required: true, message: '请输入简述' }]"
        >
          <el-input
            type="textarea"
            placeholder="请输入简述"
            size="mini"
            class="form-textarea"
            v-model="formLabelAlign.desc"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { inspectionsaveInfo, inspectionsave } from "../../assets/request/api";
import BepartmentMulti from "../../components/Bepartment_multi.vue";
export default {
  name: "system_redact",
  components: {
    BepartmentMulti,
  },
  data() {
    let skyRuel = (rule, value, callback) => {
      if (!this.$tools.verify_sky(value)) {
        callback(new Error("请输入正确天数"));
      } else {
        callback();
      }
    };
    return {
      type_name: "新建",
      status_list: [],
      check_times: [],
      formLabelAlign: {
        name: "",
        department_id: "",
        desc: "",
        score: "",
        check_times: "",
        times_days:"",
      },
       rules: {
        times_days: [
          { validator: skyRuel, trigger: "blur" },
          { required: true, message: "请输入天数" },
        ],
      },
    };
  },
  created() {
    let { id } = this.$route.query;
    this.way_show(id);
  },
  methods: {
    way_show(id) {
      inspectionsaveInfo({ id }).then((res) => {
        let { info, status_list, check_times } = res.data;
        this.check_times = check_times;
        this.status_list = status_list;
        if (id) {
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let {
            name,
            desc,
            check_depart_id,
            department_id,
            score,
            check_times,
            times_days
          } = info;
          
          formLabelAlign.desc = desc;
          formLabelAlign.times_days = times_days;
          formLabelAlign.id = id;
          formLabelAlign.name = name;
          formLabelAlign.department_id = check_depart_id;
          formLabelAlign.score = score;
          formLabelAlign.check_times = check_times;
          let item = department_id.split(",");
          this.$refs.BepartmentMulti.way_show(item);
        }
      });
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));
          let department_id = this.$refs.BepartmentMulti.way_pitch();
          if (!department_id) {
            this.$message({
              message: "请选择部门",
              type: "warning",
            });
          }
          formLabelAlign.department_id = department_id;
          inspectionsave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              setTimeout(function () {
                that.route_go();
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
  height: 32px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
.form-textarea {
  width: 550px;
  margin-bottom: 10px;
}
.execute {
  display: flex;
}
.execute /deep/ .el-input__inner {
  height: 32px;
  margin-top: 1px;
}
.qui {
  height: 400px;
  margin-bottom: 40px;
}
.form-textarea {
  width: 400px;
  margin-bottom: 10px;
}
/deep/ .el-input--mini .el-input__inner {
  height: 32px !important;
}
</style>